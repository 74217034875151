<template>
  <div>
    <transition name="fade">
      <div v-if="!cVerified" class="loading" style="transition-delay: 0.5s">
        <span>Searching for user...</span>
      </div>
    </transition>
    <div class="header">
      <a class="applogo" href="/"></a>
    </div>
    <div class="profile-wrapper">
      <div class="profile-card">
        <div class="qr-box">
          <qrcode-vue v-if="isAndroid()" :value="`cheqr.cc/${this.$route.params.id}`" level="Q" size="5.625" render-as="svg"/>
          <qr-code v-else :text="`cheqr.cc/${this.$route.params.id}`" error-level="Q" :size="512" class="qr"/>
        </div>
        <div class="details">
          <div>
          <span class="profile-name nl">{{ pName }}</span>
          <span v-if="pAffiliation" class="affiliation nl">{{ pRole }} • {{ pAffiliation }}</span>
          <span v-if="cVerified" class="verification verified">{{ cVerified }}</span>
          <span v-else class="verification unverified">Unverified</span>
          </div>
        </div>
      </div>
      <div class="permissions">
        <div>
          <Permission permTitle="Laser: Epilog Fusion Pro 48" :timestamp="pLaser01"/>
          <Permission permTitle="3D: Prusa MK3-series" :timestamp="pPrusa01"/>
          <Permission permTitle="CNC: Excitech E2-1325" :timestamp="pCNC01"/>
          <Permission permTitle="Woodworking: Router Table" :timestamp="pRT01"/>
        </div>
      </div>
      <div class="buttons">
        <a v-if="pEmail" :href="`mailto:${ pEmail }`">E-mail</a>
        <a v-else class="disabled">E-mail</a>
        <a v-if="pMobile" :href="`tel:${ pMobile }`">Phone</a>
        <a v-else class="disabled">Phone</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  margin: 0;
  padding: 0;
  user-zoom: none;
}

.loading {
  background: rgba(255,255,255,1);
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loading span {
  transform: translateY(-300%)
}

/* Loader transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.header {
  display: block;
  position:fixed;
  top:0;
  background: rgba(0,0,0,1);
  height: 4rem;
  width: 100vw;
  margin: 0;  
  z-index: 999;
  overflow: hidden;
  color: rgba(255,255,255,1);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.applogo {
  display: block;
  width: 8.5rem;
  height: 2.25rem;
  background: url('../assets/applogo.svg') center center no-repeat;
  background-size: contain;
  margin: 0 auto;
}

.profile-wrapper {
  max-width: 600px;
  padding: 1rem;
  margin: 4rem auto 0 auto;
  cursor: default;
}

.profile-card {
  padding: 0.75rem;
  background: rgba(0,0,0,0.1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
}

.details {
  display: flex;
  text-align: left;
  margin-left: 1rem;
  align-items: center;
  user-select: none;
}

.qr-box {
  background: rgba(255,255,255,1);
  padding: 0.5rem;
  border-radius: 0.1rem;
  max-height: 5.625rem;
}

.qr {
  display: block !important;
  width: 5.625rem  !important;
  height: 5.625rem !important;
  user-select: none;
}

.profile-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: rgba(0,0,0,1);
}

.affiliation {
  font-size: 1rem;
  user-select: none;
}

.verification {
  display: block;
  opacity: 0.35;
  user-select: none;
  width: 18rem;
}

.verified::before {
  content: "";
  display:inline-block;
  -webkit-mask-image: url('../assets/verified.svg');
  mask-image: url('../assets/verified.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: #2c3e50;
  height: 1.1rem;
  width: 1.1rem;
  margin: 0 0.5rem 0 0;
  transform: translateY(0.20rem);
}

.unverified::before {
  content: "";
  display:inline-block;
  -webkit-mask-image: url('../assets/unverified.svg');
  mask-image: url('../assets/unverified.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: #2c3e50;
  height: 1.1rem;
  width: 1.1rem;
  margin: 0 0.5rem 0 0;
  transform: translateY(0.20rem);
}

.nl {
  display: block;
  margin: 0 0 0.35rem 0;
}

.permissions {
  border: 2px solid rgba(0,0,0,0.1);
  border-radius: 0.5rem;
  margin: 1.5rem 0 0 0;
  padding-bottom: 0.3rem;
}

.permissions::before {
  content: "Permissions";
  display: block;
  transform: translateY(-0.8rem);
  color: rgba(0,0,0,0.15);
  font-weight: bold;
  background: rgba(255,255,255,1);
  width: 8.125rem;
  margin: 0 auto;
}

.buttons a {
  font-family: 'Jetbrains Mono', 'Courier New', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  background: rgba(0,0,0,1);
  display: block;
  padding: 1rem 0;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  color: rgba(255,255,255,1);
  margin: 1.25rem auto 0 auto;
  float: none;
  user-select: none;
}

.buttons a:active {
  transform: scale(0.95);
  margin: 1.25rem -.75rem 0 -.75rem;
}

.disabled {
  cursor: default;
  opacity: 0.15;
}
</style>

<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebasedb";
import moment from 'moment';
import Permission from '@/components/Permission.vue';


export default {
  name: "Profile",
  components: {
    Permission,
  },
  data() {
    return {
      // init variables
      pID: this.$route.params.id,
      pName: '',
      pAffiliation: '',
      pRole: '',
      pMobile: '',
      pEmail: '',
      pLaser01: 0,
      pPrusa01: 0,
      pCNC01: 0,
      pRT01: 0,


      // other variables
      cVerified: '',
      cLoaded: false,


    }
  },
  methods: {
    isAndroid() {
      // (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      if(/Android/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      
      getDoc(doc(db, 'profiles', this.$route.params.id))
      .then(snapshot => {  // DocSnapshot
      if (snapshot.exists) {
          // let profiles = snapshot.data()

          // Inject data into variables
          this.pName = snapshot.data().name
          this.pAffiliation = snapshot.data().affiliation
          this.pRole = snapshot.data().role
          this.pMobile = snapshot.data().phone
          this.pEmail = snapshot.data().email

          // Permission timestamps
          if (snapshot.data().laser01) {
            this.pLaser01 = snapshot.data().laser01.seconds
          }

          if (snapshot.data().prusa01) {
            this.pPrusa01 = snapshot.data().prusa01.seconds
          }

          if (snapshot.data().cnc01) {
            this.pCNC01 = snapshot.data().cnc01.seconds
          }

          if (snapshot.data().rt01) {
            this.pRT01 = snapshot.data().rt01.seconds
          }

          // Data verification
          if (snapshot.data().name) {
            this.cVerified = "Verified: " + moment.unix(Math.round(+new Date()/1000)).format('DD-MM-YYYY')
          }
          
          this.$nextTick();

          // Listed data
          console.log("Name: " + this.pName)
      } else {
          console.log("No such profile!");
        }  
      })
      // console.log("Profile ID " + this.$route.params.id.toString() + " is present!")
    }
  },
}

/*
async function getDocument (id) {
  const snap = await getDoc(doc(db, 'profiles', id))
  if (snap.exists())
    return snap.data()
  else
    return Promise.reject(Error(`No such profile: ${id}`))
}
*/

// console.log("Profile success", getDocument(profileID.toString()));

/* export default {
  data() {
    return {
      testCollection: [],
    };
  },
  mounted() {
    db
      .collection('profiles')
      .get()
      .then(snap => {
        const testCollection = [];
        snap.forEach(doc => {
          testCollection.push({ [doc.id]: doc.data() });
        });
        this.testCollection = testCollection;
      });
  },
  
}; */

/*
async function getDocument (id) {
  const snap = await getDoc(doc(db, 'profiles', id))
  if (snap.exists()) {
    return snap.data()
  }
  else
    return Promise.reject(Error(`No such profile: ${id}`))
}
*/
</script>