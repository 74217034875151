<template>
    <div>
        <div class="permission clickable" 
        v-if="timestamp"
        v-on:click="fetchDate"
        >
            <div class="icon-checkmark">
            </div>
            <div class="details">
                <div>
                    <span class="title block">
                        {{ permTitle }}
                    </span>
                    <span class="description block">
                        {{ permDate }}
                    </span>
                </div>
            </div>
        </div>
        <div v-else class="permission">
            <div class="icon-missing">
            </div>
            <div class="details">
                <div>
                    <span class="title block missing">
                        {{ permTitle }}
                    </span>
                    <span class="description block missing">
                        Missing
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'Permission',
    props: {
        permTitle: String,
        timestamp: Number,
    },
    data() {
        return {
        // init variables
        permDesc: '',
        courseDate: this.timestamp,
        permVer: false,
        permDate: 'Fetching data...',
        }
    },
    mounted() {
        // this.$nextTick().then(this.fetchDate());
    },
    created() {
        setTimeout(() => this.fetchDate(), 1000)

    },
    methods: {
        fetchDate() {
                if (`${this.timestamp}`.length == 10) {
                    this.permDate = String('Issued ' + moment.unix(this.timestamp).format('DD-MM-YYYY') + ' — ' + 'No expiration')
                    console.log(this.permDate)
                } else {
                    // snapshot.data() will be undefined in this case
                    this.permDate = 'Tap for details'
                    console.log("No timestamp found!");
                }
        }

}
}
</script>


<style scoped>
.permission {
    display: flex;
    flex-direction: row;
    margin: 0 1rem 0.75rem 1rem;
    user-select: none;
}

.details {
    display: flex;
    text-align: left;
    margin-left: 0.75rem;
    align-items: center;
}

.title {
    font-weight: bold;
    color: rgba(0,0,0,1);
}

.block {
    display: block;
}

.icon-checkmark {
    display: block;
    height: 2.75rem;
    width: 2.75rem;
    background: url('../assets/checkmark.svg') center center no-repeat;
}

.icon-missing {
    display: block;
    height: 2.75rem;
    width: 2.75rem;
    background: url('../assets/nocheckmark.svg') center center no-repeat;
}

.missing {
    opacity: 0.3;
}

.clickable {
    cursor: pointer;
}

</style>